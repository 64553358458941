/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.myloading-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.myloading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #14837B;
  width: 100px;
  line-height: 30px;
  margin: -15px 0 0 -50px;
  text-align: center;
  font-family: sans-serif;
  font-size: 20px;
}
.myloading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #14837B;
}
.myloading-content {
  border: 3px solid transparent;
  border-top-color: #14837B;
  border-bottom-color: #14837B;
  border-radius: 50%;
  -webkit-animation: pageloader 2s linear infinite;
  -moz-animation: pageloader 2s linear infinite;
  -o-animation: pageloader 2s linear infinite;
  animation: pageloader 2s linear infinite;
}
@keyframes pageloader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.myloading-wrapper-sm .myloading-content {
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
}
.myloading-wrapper-sm .myloading-text {
  width: 100px;
  line-height: 30px;
  margin: -15px 0 0 -50px;
  font-size: 14px;
}
