/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.log-container {
  width: 1200px !important;
  min-height: 350px;
  position: relative;
  margin: 0 auto;
  padding: 24px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.bokeh-pane::v-deep {
  width: 100%;
  text-align: left;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.bokeh-pane::v-deep h1 {
  margin-top: 32px;
  margin-bottom: 8px;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.25em;
}
